<template>
  <v-card
  elevation="0"
  outlined
  rounded>
    <v-banner
      width="100%"
      color="#CCCCCC">
      <div width="100%">
        <h2 class="font-weight-bold ntitlesdark--text text-center">{{ sTitle }}</h2>
      </div>
    </v-banner>
    <v-container>
      <v-row dense>
        <v-col cols="12" xs="6" md="3">
          <span class="titleslight--text font-weight-medium">Tipo de gráfica</span>
          <v-select
            v-model="sTypechartComponent"
            :items="aTypesComponent"
            :disabled="bDisableInput"
            item-text="sTextChart"
            item-value="sChart"
            height="30px"
            label="Seleccionar"
            hint="Selecciona el tipo de gráfica"
            persistent-hint
            outlined
            dense>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text v-if="!bValidateViewChart" class="headline font-weight"
      v-html="sTextCard">
    </v-card-text>
    <GChart v-else
      :type="sTypeChartView"
      :data="aChartData"
      :options="oChartOptions"
      :resizeDebounce="500">
    </GChart>
  </v-card>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import { GChart } from 'vue-google-charts';
  export default {
    name: "Grafica",
    props: {
      sTitle: {
        type: String,
        default: ""
      },
      sTypeChart: {
        type: String,
        default: ""
      },
      // aSetData: {
      //   type: Array
      // },
      sSection: {
        type: String,
        default: ''
      }
    },
    components : {
      GChart
    },
    data() {
      return {
        bValidateViewChart   : false,
        sTypeChartView       : (this.sTypeChart !== "") ? this.sTypeChart : "LineChart",
        sTextCard: 'No existe información.',
        aChartData: [],
        // aChartData: this.aSetData.filter(this.filterData),
        sTypechartComponent: { sTextChart: '', sChart: '' },
        aTypesComponent: [
          {sTextChart: 'Lineas', sChart: 'LineChart'},
          {sTextChart: 'Torta', sChart: 'PieChart'},
          {sTextChart: 'Barras', sChart: 'BarChart'},
          {sTextChart: 'Columnas', sChart: 'ColumnChart'},
          {sTextChart: 'Area', sChart: 'AreaChart'},
        ],
        oChartOptions: {
          legend: { 
            position: (this.sSection == '10') ? 'right' : 'none',
            textStyle : {
              fontSize: 12 // or the number you want
            }
          },
          colors: (this.sSection == '9') ? ['#00447D'] : this.aRandomColor,
          vAxes: {
            0: {title: (this.sSection == '9') ? 'Marcaciones' : (this.sSection == '10') ? 'Nivel de Riesgo' : 'Cant. Recomendaciones'},
          },
          hAxis: {
            textStyle : {
              fontSize: 12 // or the number you want
            }
          },
          height: (this.sSection == '9') ? 250 : (this.sSection == '10') ? 450 : 350,
          pointSize: 5,
        },
        aRandomColor: [],
        bDisableInput: false
      }
    },
    mounted () {
      this.getData()
    },
    methods: {
      getData() {
        axiosServices.getConfiguration(`/InformeRiesgos_DataChart/${this.sSection}`, true, '').then(aDataItems =>{
          this.cargando = false;
          this.bDisableInput = true
          if (aDataItems.aData.length !== 0) {
            this.aChartData = aDataItems.aData
            this.getRamdomColor(this.aChartData[0].length)
            this.bValidateViewChart = true
            this.bDisableInput = false
            if (this.sSection !== "10") {
              this.addColums(this.aChartData)
            }
          }
        })
      },
      getRamdomColor(nLengthHeader) {
        let aColors = []
        for (let i = 1; i<=nLengthHeader; i++) {
          let randomColor = Math.floor(Math.random()*16777215).toString(16)
          aColors.push(`"#${randomColor}"`)
        }
        this.aRandomColor = aColors
        
      },
      addColums(aInfo) {
        aInfo[0].push({role: 'style'})
        aInfo[0].push({role: 'annotation'})
        aInfo.forEach((element, i) => {
          if (i != 0) {
            element.push(`#${Math.floor(Math.random()*16777215).toString(16)}`)
            element.push(`${element[1]}`)
          }
        });
        this.aChartData = aInfo
      }
    },
    watch: {
      aSetData(aNewValue) {
        aNewValue.filter(this.filterData);
      },
      sTypechartComponent(sNewValue) {
        this.sTypeChartView = sNewValue;
      }
    }
  }
</script>