<template>
  <v-card
    v-if="!cargando"
    flat
    class="mx-auto"
    outlined>
    <v-banner
      color="#CCCCCC">
      <span class="title font-weight-bold titlesdark--text">Listado de Riesgos</span>
    </v-banner>
    <v-list
      flat
      disabled
      dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(oRisk, i) in Riesgos"
          :key="i">
          <v-list-item-content>
            <v-list-item-title class="title font-weight-bold">
              <span style="white-space: break-spaces;">{{ `R${(i + 1)}` }}</span>
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold">
              <h3 style="white-space: break-spaces;">{{ oRisk.RiesgosName }}</h3>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  props: {
    sType: {
			type: String,
			default: 'Actual'
		},
		nIdCriterio: {
			type: String,
			default: ''
		}
	},
  data() {
    return {
      Riesgos: [],
      cargando: true,
    }
  },
  async mounted() {
    await this.getRiesgos()
    this.cargando = false
  },
  methods: {
    async getRiesgos() {
      let sComplement = (this.sType == 'Actual') ? '' : this.sType 
      let sParams = `idgrupo=${this.nIdCriterio}`
			const getRisk = await axiosServices.getConfiguration(`InformeRiesgos_RiesgosMatriz${sComplement}`, false, sParams)
      this.Riesgos = getRisk.aData
		}
  }
}
</script>
<style>
  .FixedList {
    min-height: 40px !important;
  }
</style>