<template>
  <div>
		<canvas ref="matriz" width="750" height="700"></canvas>
  </div>
</template>
<script>
import { fabric } from 'fabric';
import axiosServices from '@/services/axiosServices.js'
export default {
	props: {
		sType: {
			type: String,
			default: 'Actual'
		},
		nIdCriterio: {
			type: String,
			default: ''
		}
	},
  data() {
    return {
      label: new fabric.Canvas(this.$refs.leyenda),
			Color: [],
			Impacto: [],
			Probabilidad: [],
			Riesgos: [],
			cargando: false
    }
  },
	mounted() {
		this.getColor()
  },
  methods: {
    getColor() {
      axiosServices.getConfiguration(`InformeRiesgos_ColorsMatriz`, false, '').then(getColors => {
				this.Color = getColors.aData
				this.getImpacto()
      })
		},
		getImpacto() {
			axiosServices.getConfiguration(`InformeRiesgos_ImpactoMatriz`, false, '').then(getImpact => {
        this.Impacto = getImpact.aData
				this.getProbabilidad()
      })
		},
		getProbabilidad() {
			axiosServices.getConfiguration(`InformeRiesgos_ProbabilidadMatriz`, false, '').then(getProba => {
        this.Probabilidad = getProba.aData
				this.getRiesgos()
      })
		},
		getRiesgos() {
			let sComplement = (this.sType == 'Actual') ? '' : this.sType 
			let sParams = `idgrupo=${this.nIdCriterio}`
			axiosServices.getConfiguration(`InformeRiesgos_RiesgosMatriz${sComplement}`, false, sParams).then(getRisk => {
				this.Riesgos = getRisk.aData
				this.cargando = true
      })
		},
    dibujarMatriz () {
			let matriz = new fabric.Canvas(this.$refs.matriz)
      let color = this.Color
			for (let r = 1; r <= 5; r++) {
				for (let c = 1; c <= 5; c++) {
					var rect = new fabric.Rect({
						// resto uno par mover bien desde el origen 00
						left	: 120 + (120*(c-1)),
						top		: 480 - (120*(r-1)),
						fill	: color[(r)*(c)],
						width	: 120,
						height	: 120,
						//opacity	: 0.5,
						selectable : false,
						borderColor: "white",
						strokeWidth: 1,
						stroke: 'white'
					})
					matriz.add(rect)
					matriz.sendToBack(rect)
				}	
			}
			// titulos matriz
			let Probabilidad = this.Probabilidad
			for (let x = 1; x <= 5; x++) {
				let TrectX = new fabric.Rect({
					top		: 50,
					fill	: '#FFFFFF', 
					originX : 'center',
					originY : 'center',
					width: 120,
					height	: 80,
					selectable : false,
					borderColor: "#C8C8C8",
					strokeWidth: 1
				})
				let TtextX = new fabric.IText (`${Probabilidad[(x-1)].valor.trim().replace(/\s([^\s]*)$/, '\n$1')}`, {
					fill	 : '#000000',
					fontSize : 12,
					fontFamily: 'Muli',
					fontWeight: 'bold',
					originX  : 'center',
					originY  : 'center'
				})
				var tituloX = new fabric.Group ([TrectX, TtextX], {
					left	: 120 + (120*(x-1)), 
					top		: 600,
					selectable: false
				})
				matriz.add(tituloX)
			}
			let Impacto = this.Impacto
			for (let y = 1; y <= 5; y++) {
				let TrectY = new fabric.Rect({
					fill	: '#FFFFFF', 
					originX : 'center',
					originY : 'center',
					width	: 80,
					height	: 120,
					selectable : false,
					borderColor: "#C8C8C8",
					strokeWidth: 1
				})
				let TtextY = new fabric.IText (`${Impacto[(y-1)].valor.trim().replace(/\s([^\s]*)$/, '\n$1')}`, {
					fill	 : '#000000',
					fontSize : 12,
					fontFamily: 'Muli',
					fontWeight: 'bold',
					originX  : 'center',
					originY  : 'center',
					// angle    : -90,
				})
				var tituloY = new fabric.Group ([TrectY, TtextY], {
					left	: 0, 
					top		: 480 - (120*(y-1)),
					selectable: false
				})
				matriz.add(tituloY)
			}
			this.mostrarRiesgos(matriz)
		},
		PosicionRiesgoX(probabilidad){
			// con el valor saco la posicion que debe tener esto es iniciando en 60 y sumando 120 por cada punto
			let X = 0;
			X 	  = 160 + (120*(probabilidad - 1)); 
			// X -= 20;
			return X // como el radio es 20 espor esto que lo muevo 20
		},
		PosicionRiesgoY(impacto){
			// con el valor saco la posicion que debe tener esto es iniciando en 60 y sumando 120 por cada punto
			let Y = 0;
			Y 	  = 540 - (120*(impacto - 1));
			Y -= 20;
			return Y; // como el radio es 20 espor esto que lo muevo 20
		},
		dibujarRiesgos(probabilidad, impacto, NoRiesgos, oContext) {

			let X = this.PosicionRiesgoX(probabilidad);
			let Y = this.PosicionRiesgoY(impacto);	
			// let matriz = new fabric.Canvas(this.$refs.matriz)
			//console.log(X,Y);
			
			let circle = new fabric.Circle({
				radius	: 20, 
				fill	: '#0A0A2A', 
				originX : 'center',
				originY : 'center'
			})
			
			let text = new fabric.IText (NoRiesgos, {
				fill	 : 'white',
				fontSize : 20,
				originX  : 'center',
				originY  : 'center'
			})
			
			var riesgo = new fabric.Group ([circle, text], {
				left	: X, 
				top		: Y,
				selectable: false
			})
			
			oContext.add(riesgo)
			
			// para mostrar los riesgos detalle del punto cuando estoy sobre el 
			// riesgo.on('mousedblclick', (e) => {
			//   let cord = mostrarDetalleRiesgo(e.target.left,e.target.top)
			//   let strRiesgos = MatrizRiesgosName[cord.P.toString()+cord.I.toString()].replace(/,/g,'&nbsp; - &nbsp;')
			//   //console.log(strRiesgos);
			// 	//alert(MatrizRiesgosName[cord.P.toString()+cord.I.toString()]);
			//   Swal.fire(
			// 	  'Riesgo Asociados:',
			// 	  strRiesgos,
			// 	  'info'
			//   )
			// })
		},
		mostrarRiesgos(oContext) {
			let riesgos = this.Riesgos  // llega un array de arrays
			// let nombresLeyanda = '';
			riesgos.forEach((riesgo, i) =>{				
				this.dibujarRiesgos(riesgo.Probabilidad, riesgo.Impacto, `R${(i + 1)}`, oContext);
				// nombresLeyanda += riesgo.RiesgosName + ',';
				// armo una matriz sencillar de una dimencion -- Los nombres estan separados por , 
				// MatrizRiesgosName[riesgo.Probabilidad.toString()+riesgo.Impacto.toString()]=riesgo.RiesgosName;
			});
			// mostrarLeyenda(nombresLeyanda);
		}
	},
	watch: {
		cargando (bNewvalue) {
			if (bNewvalue) {
				this.dibujarMatriz()
			}
		}
	}
}
</script>