<template>
  <div style="padding: 15px">
    <table border="1" style="width: 100%;">
      <thead style="background-color: #DCD9D9;">
        <tr>
          <th v-for="(oHeaders, i) in aHeaders" :key="i" scope="col" class="ntitlesdark--text" :style="`width: ${oHeaders.width}%; text-align: center; ${(oHeaders.visible == '0') ? 'display: none;' : ''}`">
            <h3 class="ntitlesdark--text">{{ oHeaders.text }}</h3>
          </th>
        </tr>
      </thead>
      <tbody v-if="aData.length == 0 && cargando">
        <tr>
          <td :colspan="aHeaders.length">
            <span class="font-weight-bold">No hay información</span>
          </td>
        </tr>
      </tbody> 
      <tbody v-else>
        <tr>
          <td>
            <div style="display: flex;">
              <v-container>
                <v-row dense>
                  <v-col v-for="(oImage, j) in aImage" :key="j" sm="3" md="3" lg="3" xl="3">
                    <div id="sImgReference" >
                      <img class="aFotos" style="margin: 10px; width: 180px; height: 180px;" :src="oImage"/>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  name: 'gridanidada',
  props: {
    sIdParent: {
      type: String,
      default: ''
    },
    sTabla: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      bShow: false,
      bIcon: false,
      aHeaders: [],
      aData: [],
      aImage: [],
      cargando: true
    }
  },
  async mounted () {
    await this.getHeaders()
    await this.getData()
    this.cargando = false
  },
  methods: {
    async getHeaders () {
      const aHeaders = await axiosServices.getConfiguration('/InformeRiesgos_headersGrid', true, `sTable=${this.sTabla}&level=three`)
      this.aHeaders = aHeaders.aData
    },
    async getData () {
      let sParams = `sTable=${this.sTabla}&idparent=${this.sIdParent}&level=three`
      const aDataItems = await axiosServices.getConfiguration('/InformeRiesgos_DataGrid', true, sParams)
      this.aData = aDataItems.aData
      this.getBase64()
    },
    getBase64 () {
      this.aData.forEach(async oImageName => {
        await axiosServices.getConfiguration(`/InformeRiesgos_Base64/${oImageName[0].text}`, false, '').then(getBase64 => {
          this.aImage.push(getBase64.aData)
          this.resize()
        })
      })
    },
    resize () {
      if (window.addEventListener) {  // all browsers except IE before version 9
        window.addEventListener("resize", onResizeEvent, true);
      }
      function onResizeEvent() {
        let divImgElement = document.getElementById("sImgReference")
        let newWidth = divImgElement.clientWidth
        let aImgsRenders = document.getElementsByClassName("aFotos")
        aImgsRenders.forEach(oElementImg => {
          oElementImg.style.width = `${newWidth}px`
          oElementImg.style.height = `${newWidth}px`
        })
      }
    },
  }
}
</script>