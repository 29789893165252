<template>
  <div v-if="!cargando" style="padding: 15px">
    <table border="1" style="width: 100%;">
      <thead style="background-color: #DCD9D9;">
        <tr>
          <th v-for="(oHeaders, i) in aHeaders" :key="i" scope="col" class="ntitlesdark--text" :style="`width: ${oHeaders.width}%; text-align: center; ${(oHeaders.visible == '0') ? 'display: none;' : ''}`">
            <h3 class="ntitlesdark--text">{{ oHeaders.text }}</h3>
          </th>
        </tr>
      </thead>
      <tbody v-if="aData.length == 0">
        <tr>
          <td :colspan="aHeaders.length">
            <span class="font-weight-bold">No hay información</span>
          </td>
        </tr>
        <tr v-if="aHeaders[0].detalle == '1'" :id="`s${sIndicaSeccion}_${sIdParent}_0`" class="for-export" style="display: table-row; text-align: center;">
          <td :colspan="aHeaders.length">
            <GridLastLevel
              :sIdParent="`${sIdParent}`"
              :sTabla="`${oTablas.Three}`">
            </GridLastLevel>
          </td>
        </tr>
      </tbody>
      <tbody v-else v-for="(oData, i) in aData" :key="i"> 
        <tr>
          <td v-for="(oData, j) in aData[i]" :key="j" :style="`background-color: ${oData.color}`">
            <span style="font-size: 15px;">{{ oData.text }}</span>
          </td>
        </tr>
        <tr v-if="aHeaders[0].detalle == '1'" :id="`s${sIndicaSeccion}_${sIdParent}_${i}`" class="for-export" style="display: table-row; text-align: center;">
          <td :colspan="aHeaders.length">
            <GridLastLevel
              :sIdParent="`${sIdParent}`"
              :sTabla="`${oTablas.Three}`">
            </GridLastLevel>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import GridLastLevel from '@/components/Reportes/Componentes/GridLastLevel.vue'
export default {
  name: 'gridanidada',
  props: {
    sIdParent: {
      type: String,
      default: ''
    },
    sIdParent2: {
      type: String,
      default: ''
    },
    oTablas: {
      type: Object
    },
    sIndicaSeccion: {
      type: String,
      default: ''
    }
  },
  components: {
    GridLastLevel
  },
  data () {
    return {
      bShow: false,
      bIcon: false,
      aHeaders: [],
      aData: [],
      cargando: true
    }
  },
  async mounted () {
    await this.getHeaders()
    await this.getData()
    this.cargando = false
  },
  methods: {
    async getHeaders () {
      this.cargando = true
      const aHeaders = await axiosServices.getConfiguration('/InformeRiesgos_headersGrid', true, `sTable=${this.oTablas.Second}&level=second`)
      this.aHeaders = aHeaders.aData
    },
    async getData () {
      let idparend2 = (this.sIdParent2 !== 0) ? `&idparent2=${this.sIdParent2}` : ''
      let sParams = `sTable=${this.oTablas.Second}&idparent=${this.sIdParent}${idparend2}&level=second`
      const aDataItems = await axiosServices.getConfiguration('/InformeRiesgos_DataGrid', true, sParams)
      this.aData = aDataItems.aData
    },
  }
}
</script>