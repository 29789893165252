<template>
  <div>
    <div v-if="cargando">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <v-container>
        <v-row justify="center">
          <v-col sm="12" md="12" lg="12" xl="12">
            <p class="font-weight-black titlesdark--text text-center display-1">{{ sTitle }}</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container v-if="!cargando" class="pa-0">
        <v-row dense align="center" justify="center">
          <v-col sm="12" md="12" lg="12" xl="12">
            <v-btn width="100%" text small color="btncolor" @click="printSection()" dark> 
              <span class="font-weight-bold title">Exportar</span> 
              <v-icon small right dark>fa-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
    </v-container>
    <div id="printPage" style="background: #FFFFFF;">
      <v-container v-if="!cargando" class="imprimir">
        <v-row dense justify="center">
          <v-col sm="2" md="2" lg="2" xl="2">
            <div id="sImgReference" style="border: 1px; text-align: center;">
              <v-img id="Logo" style="width: 150px; height: 150px;" :src="LogoImageURL" contain></v-img>
            </div>
          </v-col>
          <v-col sm="10" md="10" lg="10" xl="10">
            <v-banner
              class="bnrHead"
              min-height="100">
              <div v-for="(oDataHead, i) in aDataHead" :key="i" >
                <span class="title font-weight-bold" style="color: white;">{{ i }}&nbsp;&nbsp;</span><span class="title" style="color: white;">{{ oDataHead }}</span>
                <br>
              </div>
            </v-banner>
            <v-container fluid>
              <v-row dense align="center" justify="center">
                <v-col sm="6" md="6" lg="6" xl="6">
                  <span class="title ntitlesdark--text pl-1 text-center">Fecha reporte: {{ Fecha }}</span>
                </v-col>
                <v-col sm="6" md="6" lg="6" xl="6">
                  <span class="title ntitlesdark--text pl-1 text-center">Version: R001</span>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!cargando" class="imprimir">
        <v-row justify="center" align="center" dense>
          <v-col sm="12" md="12" lg="12" xl="12">
            <v-banner
              width="100%"
              color="#CCCCCC">
              <div width="100%">
                <h2 class="font-weight-bold ntitlesdark--text text-center">Informe de gestión de riesgos</h2>
              </div>
            </v-banner>
          </v-col>
        </v-row>
        <v-row v-if="aElement.includes('1') || aElement.includes('2') || aElement.includes('3') || aElement.includes('6')">
          <v-col v-for="(oDataCard, i) in aDataCard" :key="i" sm="3" md="3" lg="3" xl="3">
            <Cards
              :aData="oDataCard">
            </Cards>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!cargando && aElement.includes('4')">
        <div class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-banner
                width="100%"
                color="#CCCCCC">
                <div width="100%">
                  <h2 class="font-weight-bold ntitlesdark--text text-center">Evaluación de riesgos</h2>
                </div>
              </v-banner>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col sm="12" md="12" lg="12" xl="12">
            <Grid
              :oTablas="oTablesGrid1"
              :sIndicaSeccion="'4'">
            </Grid>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!cargando && aElement.includes('5')">
        <div v-for="(oTypeMatriz, i) in aTypeMatriz" :key="i" class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-banner
                width="100%"
                color="#CCCCCC">
                <div width="100%">
                  <h2 class="font-weight-bold ntitlesdark--text text-center">Matriz de riesgos Inicial (Criterio de impacto {{ oTypeMatriz.Titulo }})</h2>
                </div>
              </v-banner>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="8" xl="8">
              <Matriz
                :sType="'Inicial'"
                :nIdCriterio="oTypeMatriz.ID">
              </Matriz>
            </v-col>
            <v-col sm="12" md="12" lg="4" xl="4">
              <ListRisk
                :sType="'Inicial'"
                :nIdCriterio="oTypeMatriz.ID">
              </ListRisk>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-banner
                width="100%"
                color="#CCCCCC">
                <div width="100%">
                  <h2 class="font-weight-bold ntitlesdark--text text-center">Matriz de riesgos Actual (Criterio de impacto {{ oTypeMatriz.Titulo }})</h2>
                </div>
              </v-banner>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="8" xl="8">
              <Matriz
                :sType="'Actual'"
                :nIdCriterio="oTypeMatriz.ID">
              </Matriz>
            </v-col>
            <v-col sm="12" md="12" lg="4" xl="4">
              <ListRisk
                :sType="'Actual'"
                :nIdCriterio="oTypeMatriz.ID">
              </ListRisk>
            </v-col>
          </v-row>
        </div>
        <div class="imprimir">
          <v-row>
            <v-col sm="12" md="12" lg="12" xl="12">
              <Convenciones></Convenciones>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container v-if="!cargando && aElement.includes('7')">
        <div class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-banner
                width="100%"
                color="#CCCCCC">
                <div width="100%">
                  <h2 class="font-weight-bold ntitlesdark--text text-center">Controles Realizados</h2>
                </div>
              </v-banner>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col sm="12" md="12" lg="12" xl="12">
            <Grid
              :oTablas="oTablesGrid2"
              :sIndicaSeccion="'7'">
            </Grid>
          </v-col>
        </v-row>
        <div class="imprimir">
          <v-row>
            <v-col sm="12" md="12" lg="12" xl="12">
              <Graficas
                sTitle="Recomendaciones"
                :sTypeChart="'ColumnChart'"
                :sSection="'7'">
              </Graficas>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container v-if="!cargando && aElement.includes('9')">
        <div class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <Graficas
                sTitle="Historico Marcaciones"
                :sTypeChart="'BarChart'"
                :sSection="'9'">
              </Graficas>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container v-if="!cargando && aElement.includes('10')">
        <div class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <Graficas
                sTitle="Registro mes anterior"
                :sTypeChart="'LineChart'"
                :sSection="'10'">
              </Graficas>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container v-if="!cargando && aElement.includes('8')">
        <div class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-banner
                width="100%"
                color="#CCCCCC">
                <div width="100%">
                  <h2 class="font-weight-bold ntitlesdark--text text-center">Gestión Vecinos</h2>
                </div>
              </v-banner>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col sm="12" md="12" lg="12" xl="12">
            <Grid
              :oTablas="oTablesGrid3"
              :sIndicaSeccion="'4'">
            </Grid>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <div class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-banner
                width="100%"
                color="#CCCCCC">
                <div width="100%">
                  <h2 class="font-weight-bold ntitlesdark--text text-center">{{TituloAdicional}}</h2>
                </div>
              </v-banner>
            </v-col>
          </v-row>
        </div>
        <div v-if="bdisplay">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-card
                elevation="0">
                <v-textarea
                  v-model="TextoAdicional"
                  :readonly="bShowExport"
                  outlined>
                </v-textarea>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="font-weight-bold text-center"
                    color="#012883"
                    @click="bdisplay = false"
                    dark>
                    <v-icon left>
                      fas fa-save
                    </v-icon>
                    Guardar {{ TituloAdicional }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else class="imprimir">
          <v-row justify="center" align="center" dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <v-card
                flat
                outlined
                rounded>
                <span style="font-size: 15px; font-color: #000000;">
                  {{ TextoAdicional }}
                </span>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <v-dialog v-model="bShowExport" persistent width="250">
      <v-card
        color="primary"
        dark>
        <v-card-title>
          Generando PDF...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular
            active
            indeterminate
            color="white"
            class="mb-0">
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { mapGetters } from 'vuex'
import Grid from '@/components/Reportes/Componentes/Grid.vue'
import Cards from '@/components/Reportes/Componentes/CardsTotal.vue'
import Matriz from '@/components/Reportes/Componentes/MatrizRiesgos.vue'
import ListRisk from '@/components/Reportes/Componentes/ListaRiesgos.vue'
import Convenciones from '@/components/Reportes/Componentes/Convenciones.vue'
import Graficas from '@/components/Reportes/Componentes/Graficas.vue'
export default {
  components: {
    Grid,
    Cards,
    Matriz,
    ListRisk,
    Convenciones,
    Graficas
  },
  data() {
    return {
      widthColConvencion: 0,
      sTitle: 'Generando informe...',
      cargando: false,
      Fecha: '',
      LogoImageURL: '',
      aDataHead: [],
      aDataCard: [],
      aElement: [],
      aGrupos: [],
      aTypeMatriz: [],
      oTablesGrid1: {
        Master: 'RiesgoNovedades',
        Second: 'RiesgoRiesgosNovedad',
        Three: 'RiesgoAnexosNovedades'
      },
      oTablesGrid2: {
        Master: 'RiesgoRiesgosNovedad',
        Second: 'RiesgoRecomendacion',
        Three: ''
      },
      oTablesGrid3: {
        Master: 'VECINOS',
        Second: '',
        Three: 'VECINOS_Anexos'
      },
      bShowExport: false,
      mostrarbtnexportar: false,
      TituloAdicional: '',
      TextoAdicional: '',
      bdisplay: true
    }
  },
  async mounted() {
    this.getElements()
    await this.getDataCards()
    await this.getLogo()
    await this.getHeader()
    await this.getTitleMatriz()
    await this.getTitleTextoAdicional()
    this.cargando = false
  },
  methods: {
    getElements () {
      let aParams = this.getsParams.split('&')
      let aGet = {}
      // recorremos todo el array de valores
      for(let i = 0, l = aParams.length; i < l; i++){
        let tmp = aParams[i].split('=')
        aGet[tmp[0]] = unescape(decodeURI(tmp[1]))
      }
      this.aElement = aGet['elementos'].split(';')
      this.Fecha = `${aGet['desde']} - ${aGet['hasta']}`
      this.aGrupos = aGet['grupos'].split(';')
    },
    getLogo () {
      this.cargando = true
      let sParams = 'nCliente='+ this.$route.query.cliente
      axiosServices.getConfiguration('FormDinamicoReporte_Path', false, sParams).then(getPath => {
        this.sLogoImg = getPath.aData
        this.proccessData()
      })
    },
    async getHeader () {
      const getHeader = await axiosServices.getConfiguration(`InformeRiesgos_Header`, false, '')
      this.aDataHead = getHeader.aData
    },
    async getDataCards () {
      const getCards = await axiosServices.getConfiguration(`InformeRiesgos_Cards`, false, '')
      this.aDataCard = getCards.aData
    },
    async getTitleMatriz () {
      if (this.aGrupos.length > 0) {
        const getTitle = await axiosServices.getConfiguration(`InformeRiesgos_Titles`, false, '')
        this.aTypeMatriz = getTitle.aData
      }
    },
    async getTitleTextoAdicional () {
      const getTitleTextoAdicional = await axiosServices.getConfiguration(`InformeRiesgos_TituloAdicional`, false, '')
      this.TituloAdicional = getTitleTextoAdicional.aData.NombreElemento
    },
    async printSection() {
      this.bShowExport = true
      let opentables = document.getElementsByClassName('for-export')
      for (let i = 0; i < opentables.length; i++) {
        opentables[i].style.display = "table-row"
        opentables[i].style["text-align"] = "center"
      }
      let elements = document.querySelectorAll("#printPage .imprimir")
      let doc = new jsPDF('p', 'mm', 'a4', true)
      doc.setFillColor(245);
      doc.rect(0, 0, 210, 700, "F");
      const imgWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight() - 5
      let position = 5
      let pageHeightLeft = pageHeight
      for (let i = 0; i < elements.length; i++) {
        await html2canvas(elements[i], {
          scale: 2,
          useCORS: true,
          allowTaint: true
        }).then(function(canvas) {
          let imgData = canvas.toDataURL('image/png', 1.0)
          let imgHeight = canvas.height * imgWidth / canvas.width
          // Comprobamos salto
          if (pageHeightLeft - imgHeight <= 0) {
            doc.addPage()
            pageHeightLeft = pageHeight
            position = 5 // Pintaremos en el inicio de la nueva pagina
          }
          doc.addImage(imgData, 'PNG', 0, position, (imgWidth - 2), imgHeight, '', 'FAST')
          position += imgHeight // Marcamos el siguiente inicio
          pageHeightLeft -= imgHeight // Marcamos cuanto espacio nos queda
        })
      }
      // const sNameFile = new Date().toISOString().substr(0, 10)
      doc.save(`Informe de seguridad ${this.Fecha}.pdf`)
      this.bShowExport = false
    },
    resize () {
      if (window.addEventListener) {  // all browsers except IE before version 9
        window.addEventListener("resize", onResizeEvent, true);
      }
      function onResizeEvent() {
        let divImgElement = document.getElementById("sImgReference")
        let newWidth = divImgElement.clientWidth
        let oImgRender = document.getElementById("Logo")
        oImgRender.style.width = `${newWidth}px`
        oImgRender.style.height = `${newWidth}px`
      }
    },
  },
  computed: {
    ...mapGetters([
      'getsParams'
    ])
  },
  watch: {
    cargando(oNew) {
      if (oNew === false) {
        this.resize()
      }
    }
  },
}
</script>
<style>
  .bnrHead {
    background-image: linear-gradient(to bottom, #15adc7, #0089b5, #00659e, #004281, #011f5f);
  }
  .v-banner__content {
    overflow: visible !important;
  }
  .v-banner__text {
    line-height: 20px;
    width: 100% !important;
  }
</style>