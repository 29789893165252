<template>
  <div v-if="!cargando">
    <table style="width: 100%;">
      <thead style="background-color: #DCD9D9;" class="imprimir">
        <tr>
          <th v-for="(oHeaders, i) in aHeaders" :key="i" scope="col" :style="`width: ${oHeaders.width}%; text-align: center; ${(oHeaders.visible == '0') ? 'display: none;' : ''}`">
            <h3 class="ntitlesdark--text">{{ oHeaders.text }}</h3>
          </th>
        </tr>
      </thead>
      <tbody v-if="aData.length == 0" class="imprimir">
        <tr>
          <td :colspan="aHeaders.length">
            <span class="font-weight-bold">No hay información</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else v-for="(oData, i) in aData" :key="i" class="imprimir">
        <tr>
          <td v-for="(oDataField, j) in oData" :key="j" :style="`background-color: ${oDataField.color}`">
            <span style="font-size: 15px;">{{ oDataField.text }}</span>
          </td>
        </tr>
        <tr v-if="aHeaders[0].detalle == '1'" :id="`m${sIndicaSeccion}_${oData[0].id}_${i}`" class="for-export" style="display: table-row; text-align: center;">
          <td :colspan="aHeaders.length">
            <GridAnidada
              :sIdParent="oData[0].id"
              :sIdParent2="oData[0].id2"
              :oTablas="oTablas"
              :sIndicaSeccion="`${sIndicaSeccion}`">
            </GridAnidada>
          </td>
        </tr>
        <tr v-else-if="aHeaders[0].detalle == '2'" :id="`m${sIndicaSeccion}_${oData[0].id}_${i}`" class="for-export" style="display: table-row; text-align: center;">
          <td :colspan="aHeaders.length">
            <GridLastLevel
              :sIdParent="`${oData[0].id}`"
              :sTabla="`${oTablas.Three}`">
            </GridLastLevel>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import GridAnidada from '@/components/Reportes/Componentes/GridAnidada.vue'
import GridLastLevel from '@/components/Reportes/Componentes/GridLastLevel.vue'
export default {
  props: {
    oTablas: {
      type: Object
    },
    sIndicaSeccion: {
      type: String,
      default: ''
    }
  },
  components: {
    GridAnidada,
    GridLastLevel
  },
  data () {
    return {
      bShow: false,
      bIcon: false,
      aHeaders: [],
      aData: [],
      cargando: true
    }
  },
  async mounted () {
    await this.getHeaders()
    await this.getData()
    this.cargando = false
  },
  methods: {
    async getHeaders () {
      const aHeaders = await axiosServices.getConfiguration('/InformeRiesgos_headersGrid', true, `sTable=${this.oTablas.Master}&level=primary`)
      this.aHeaders = aHeaders.aData
      // this.getData()
    },
    async getData () {
      let sParams = `sTable=${this.oTablas.Master}&level=primary`
      const aDataItems = await axiosServices.getConfiguration('/InformeRiesgos_DataGrid', true, sParams)
      this.aData = aDataItems.aData
    }
  },
}
</script>
<style>
  table {
    table-layout: fixed;
  }
  table, td, th {
    border: 1px solid #C8C8C8;
    border-collapse: collapse;
    text-align: center;
    text-align: -webkit-center;
  }
  td {
    word-wrap: break-word; 
  }
</style>