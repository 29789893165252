import { render, staticRenderFns } from "./ListaRiesgos.vue?vue&type=template&id=81a6c128&"
import script from "./ListaRiesgos.vue?vue&type=script&lang=js&"
export * from "./ListaRiesgos.vue?vue&type=script&lang=js&"
import style0 from "./ListaRiesgos.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBanner,VCard,VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle})
