<template>
  <div>
    <v-card
      flat
      min-width="200"
      min-height="230"
      outlined
      rounded>
      <v-card-text>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col sm="12" md="12" lg="12" xl="12">
              <span class="title titlesdark--text">{{ aData.Titulo }}</span>
            </v-col>
          </v-row>
          <v-row dense justify="space-between" align="center" no-gutters>
            <v-col sm="5" md="5" lg="5" xl="5">
              <h1 class="titlesdark--text">{{ aData.Valor }}</h1>
            </v-col>
            <v-col sm="5" md="5" lg="5" xl="5">
              <div v-if="aData.Visualizaicono == '1'">
                <v-icon v-if="(parseInt(aData.Valor) > parseInt(aData.Valor2))" color="red" size="70">fas fa-caret-up</v-icon>
                <v-icon v-else-if="(parseInt(aData.Valor) < parseInt(aData.Valor2))" color="green" size="70">fas fa-caret-down</v-icon>
                <v-icon v-else color="blue" size="70">fas fa-minus</v-icon>
              </div>
              <div v-else>
                <v-icon color="white" size="40">fas fa-minus</v-icon>
              </div>
            </v-col>
          </v-row>
          <v-row dense justify="space-between" align="center" no-gutters>
            <v-col sm="5" md="5" lg="5" xl="5">
              <span class="titlesdark--text">{{ aData.Subtitulo1 }}</span>
              <br>
              <h2 class="titlesdark--text">{{ aData.Valor1 }}</h2>
            </v-col>
            <v-col sm="5" md="5" lg="5" xl="5">
              <span class="titlesdark--text">{{ aData.Subtitulo2 }}</span>
              <br>
              <h2 class="titlesdark--text">{{ aData.Valor2 }}</h2>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "cards",
  props: {
    aData: {
      type: Object
    }
  },
  data () {
    return {
    }
  }
}
</script>