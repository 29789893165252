<template>
  <v-row v-if="!cargando" align="center" dense no-gutters>
    <v-col sm="12" md="12" lg="12" xl="12">
      <v-banner
        width="100%"
        color="#CCCCCC">
        <div width="100%">
          <h2 class="font-weight-bold ntitlesdark--text text-center">Convenciones</h2>
        </div>
      </v-banner>
    </v-col>
    <v-col v-for="(oConvencions, i) in aConvencions" :key="i" sm="2" md="2" lg="2" xl="2">
      <div style="border: 1px solid #C8C8C8;">
        <v-card
          flat
          height="80"
          outlined
          tile
          :color="oConvencions.sColor">
          <v-card-title>
            <span class="font-weight-bold text-center" style="font-size: 19px;">{{ oConvencions.sTexto }}</span>
          </v-card-title>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  name: 'convenciones',
  data () {
    return {
      aConvencions: [],
      cargando: false
    }
  },
  async mounted () {
    await this.getConvencions()
    this.cargando = false
  },
  methods: {
    async getConvencions () {
      this.cargando = true
      const getConvenciones = await axiosServices.getConfiguration(`InformeRiesgos_ConvencionesMatriz`, false, '')
      this.aConvencions = getConvenciones.aData
    }
  },
}
</script>